.nf-repeater-fieldsets{
    fieldset {
        margin-bottom: 1em;
    }
}

.nf-remove-fieldset {
    color: red;
    font-size: 1.5em;
    background-color: transparent;
    border: thin solid red;
    padding: 0.1em 0.4em;
    float: right;
    margin-top: -1em;
}